import $ from 'jquery';

$('#contact-form').on('submit', function () {
    const action = 'contact';
    grecaptcha.ready(function () {
        try{
            grecaptcha.execute('6LdMvscUAAAAAF5xTXcMtiA2zfqIk_FdXXYcO9vt', {action: action}).then(function (token) {
                console.log('token!');
                $.post('email.php', {
                    token: token,
                    action: action,
                    name: $('#input-name').val(),
                    reason: $('#input-reason').val(),
                    email: $('#input-email').val(),
                    message: $('#input-message').val(),
                }).done((data) => {
                    if (data.status) {
                        $('#contact-form-success-message').show('slow');
                        $('#input-name').val('');
                        $('#input-reason').val('');
                        $('#input-email').val('');
                        $('#input-message').val('');
                    } else {
                        $('#contact-form-failure').show('slow');
                    }
                }).fail((data) => {
                    $('#contact-form-failure').show('slow');
                });
            });
        }catch (e) {
            console.log('error!');
        }

    });
    return false;
});
